// extracted by mini-css-extract-plugin
export var aArrow1 = "tb";
export var aArrow2 = "ub";
export var aButton1 = "db";
export var aButton2 = "eb";
export var aButton3 = "fb";
export var aButton4 = "gb";
export var aButton5 = "hb";
export var aButtonHeaderLogin = "ib";
export var aButtonHeaderRegister = "jb";
export var aButtonHeaderSpLogin = "mb";
export var aButtonLogin = "lb";
export var aButtonRegister = "kb";
export var aLabel = "xb";
export var aSelect = "yb";
export var aTagAnnounce = "qb";
export var aTagClose = "pb";
export var aTagEntry = "nb";
export var aTagFree = "sb";
export var aTagLottery = "rb";
export var aTagPayment = "ob";
export var animationMarquee = "zb";
export var pListController = "vb";
export var pListController__inner = "wb";