// extracted by mini-css-extract-plugin
export var aArrow1 = "Ic";
export var aArrow2 = "Jc";
export var aButton1 = "yc";
export var aButton2 = "zc";
export var aButton3 = "Ac";
export var aButton4 = "Bc";
export var aButton5 = "Cc";
export var aButtonHeaderLogin = "Dc";
export var aButtonHeaderRegister = "Ec";
export var aButtonHeaderSpLogin = "Hc";
export var aButtonLogin = "Gc";
export var aButtonRegister = "Fc";
export var aDate = "xc";
export var aEmpty = "tc";
export var aInner = "wc";
export var aText = "Kc";
export var aTitle = "uc";
export var animationMarquee = "Lc";
export var pNewsList = "vc";